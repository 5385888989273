// Core

@import 'argon-dashboard/custom/functions';
@import 'argon-dashboard/custom/variables';
@import 'argon-dashboard/custom/mixins';

// bootstrap (4.6.0) components

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// Utilities and components

@import 'argon-dashboard/custom/reboot';
@import 'argon-dashboard/custom/utilities';
@import 'argon-dashboard/custom/components';

// Vendor (Plugins)

@import 'argon-dashboard/custom/vendors';

// Docs components

@import 'argon-dashboard/docs/variables';
@import 'argon-dashboard/docs/nav';
@import 'argon-dashboard/docs/clipboard-js';
@import 'argon-dashboard/docs/component-examples';
@import 'argon-dashboard/docs/prism';
@import 'argon-dashboard/docs/content';
@import 'argon-dashboard/docs/sidebar';
@import 'argon-dashboard/docs/footer';

// React Differences
@import 'react/react-differences';

// Manual styles

#root {
  background-color: #1172ef;
}

.rc-time-picker-input {
  width: 100% !important;
  padding-left: 0 !important;
  padding: 0.625rem 0.75rem !important;
  margin-top: -3px !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #8898aa !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: none !important;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}

.rc-time-picker-clear {
  top: 10px !important;
}

.img-invert-color {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

select {
  -webkit-appearance: none;
}

.folderDelete {
  margin: 0 auto !important;
  width: 90%;
}

.hover-transition-disabled {
  &:hover {
    transition: none !important;
    transform: none !important;
  }
}

.kiosk-left {
  // border: 5px solid #000000;
  // height: 100vh;
  background-color: #1172ef;
  color: #ffffff;
}

.kiosk-right {
  // border: 5px solid #030303;
  height: 100vh;
  background-color: #1172ef;
}

.btn-keypad {
  border-color: #ffffff;
}

.kiosk-time-card,
.kiosk-qrcode-card {
  background-color: transparent;
  border: none;
  padding: 25px 25px 25px 25px;
  text-align: center;
}

.kiosk-qrcode-card {
  //margin-top: 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  // height: 410px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.kiosk-day {
  font-size: 1.6rem;
}

.kiosk-date {
  font-size: 1.6rem;
}

.kiosk-copy {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 25px;
}

.kiosk-qrcode-reader-cam {
  // background-color: #ffffff;
  // width: 250px;
  // height: 250px;
  margin: 0 auto;
  margin-top: 92px;
}

.kiosk-qrcode-reader-a {
  margin: 0 auto;
  padding-top: 25%;
}

.kiosk-qrcode-reader-cam .col {
  // width: 300px;
}

.btn-circle.btn-md.btn-md,
.btn-circle.btn-md-warning,
.btn-circle.btn-md-success {
  width: 70px;
  height: 70px;
  padding: 12px 15px;
  border-radius: 35px;
  font-size: 1.25rem;
  text-align: center;
}
.btn-circle.btn-md {
  background-color: #1172ef;
}

.btn-circle.btn-md-warning {
  background-color: #f7c008;
  border-color: #f7c008;
}

.buttonsNumbers .col .btn {
  margin: 25px;
}

.kiosk-keypad-main {
  .keypad-col {
    margin-top: 20px;
    text-align: center;
  }
}

.kiosk-keypad-mail-col {
  max-width: 500px;
  // margin: 0 auto;
  text-align: center;
  // vertical-align: middle;
  // position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 620px;
}

.kiosk-keypad-monitor {
  width: 80%;
  margin: 0 auto;
}

.kiosk-keypad-pin-hidden {
  width: 80%;
  max-width: 250px;
  margin: 0 auto;
  background-color: #1172ef !important;
  border: solid 1px #fff;
  color: #fff;
  height: 80px;
  box-shadow: none;
  font-size: 4em;
  text-align: center;
}

.form-control:disabled {
  background-color: #f8f9fe;
}

.kiosk-card-user {
  margin-top: 15px;
}

.kiosk-card-user,
.kiosk-card-kids {
  padding: 25px;
}

.kiosk-button-return {
  padding: 15px;
  text-align: right;
}

.kiosk-icon-checkin-checkout,
.kiosk-button-make-checkin-checkout {
  margin-top: 40px;
}
